<template>
  <App
    title="POC"
    left-arrow
  >
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />
    <FooterBtn
      @cancel='cancel'
      @ok="onSave"
    ></FooterBtn>
  </App>
</template>
<script>
import { Toast } from 'vant'
import DynamicForm from '_c/dynamicForm'
import FooterBtn from '_c/footerBtn/index'
import { getBrandCity, oppSave } from '@/api/chargequery/index'
import { accountDetail, pocInfo } from '@/api/clue/index'
export default {
  name: 'poc',
  components: {
    DynamicForm,
    FooterBtn
  },
  data () {
    return {
      model: {
        type: '00',
        name: '',
        checked: '',
        cardType: '',
        cardNo: '',
        prisename: '',
        code: '',
        loanFlag: '',
        licenseFlag: '',
        licenseCity: '',
        namea: '',
        phone: '',
        cardNoa: '',
        remark: ''
      },
      cityList: [],
      userInfo: {},
      isshow: false,
      meanwhile: true,
      car: [{ 'label': '是', 'value': 'yes' }, { 'label': '否', 'value': 'no' }],
      rules: {
        type: [{ required: true, message: '请填写所有人类型' }],
        name: [{ required: true, message: 'Please fill in the name' }],
        cardType: [{ required: true, message: 'Please select the ID type' }],
        cardNo: [{ required: true, message: '请输入证件号码' }],
        prisename: [{ required: true, message: '请输入企业名称' }],
        code: [{ required: true, message: '请输入企业代码' }],
        loanFlag: [{ required: true, message: '请输入金融方案' }],
        licenseFlag: [{ required: true, message: '请输入上牌资质' }],
        licenseCity: [{ required: true, message: '请输入上牌城市' }],
        namea: [{ required: true, message: '请输入主用车人姓名' }],
        phone: [{ required: true, message: '请输入主用车人手机号' }],
        cardNoa: [{ required: true, message: '请输入主用车人证件号' }]
      },
      accountId: '',
      oppId: ''
    }
  },
  computed: {
    fieldData () {
      return [
        {
          title: '车辆所有人',
          data: [
            { label: '所有人类型', key: 'type', type: 'select', options: this.allType, props: {} },
            { label: '姓名', key: 'name', visible: this.model.type === '00', props: { maxlength: 20 } },
            { label: '证件类型', key: 'cardType', type: 'select', options: this.documentType, visible: this.model.type === '00' },
            { label: '证件号码', key: 'cardNo', visible: this.model.type === '00', props: { maxlength: 20 } },
            { label: '企业名称', key: 'prisename', visible: this.model.type !== '00', props: { maxlength: 30 } },
            { label: '企业代码', key: 'code', visible: this.model.type !== '00', props: { maxlength: 20 } }
          ]
        },
        {
          title: '主用车人（可选）',
          data: [
            { label: '同车辆所有人信息', key: 'checked', type: 'select', options: this.car, props: { labelWidth: '9em' } },
            { label: '姓名', key: 'namea', type: '', props: { disabled: this.model.checked === 'yes', maxlength: 20 } },
            { label: '手机号码', key: 'phone', type: '', props: { disabled: this.model.checked === 'yes', maxlength: 11 } },
            { label: '证件号码', key: 'cardNoa', type: '', visible: this.model.type === '00', props: { disabled: this.model.checked === 'yes', maxlength: 20 } }
          ]
        },
        {
          title: '金融方案',
          data: [
            { label: '金融方案', key: 'loanFlag', type: 'select', options: this.finance }
          ]
        },
        {
          title: '上牌信息',
          data: [
            { label: '上牌资质', key: 'licenseFlag', type: 'select', options: this.Registration },
            { label: '上牌城市', key: 'licenseCity', type: 'select', options: this.cityList }
          ]
        },
        {
          title: '备注（可选）',
          data: [
            { label: '备注信息', key: 'remark', direction: 'column', props: { placeholder: '请输入备注', type: 'textarea', border: true, maxlength: 500 } }
          ]
        }
      ]
    }
  },
  watch: {
    'model.type': {
      handler (e) {
        // 00个人
        if (e === '00') {
          this.model.prisename = ''
          this.model.code = ''
          this.rules['namea'] = [{ required: true, message: '请输入主用车人姓名' }]
          this.rules['phone'] = [{ required: true, message: '请输入主用车人手机号' }]
          this.rules['cardNoa'] = [{ required: true, message: '请输入主用车人证件号' }]
        } else {
          delete this.rules.namea
          delete this.rules.phone
          delete this.rules.cardNoa
          this.model.cardType = ''
          this.model.cardNo = ''
          this.model.cardNoa = ''
        }
      },
      deep: true,
      immediately: true,
    },

    'model.checked': {
      handler (e) {
        if (e === 'yes') {
          this.model.namea = this.model.name
          this.model.phone = this.userInfo.phone
          this.model.cardNoa = this.model.cardNo
        } else {
          this.model.namea = ''
          this.model.phone = ''
          this.model.cardNoa = ''
        }
      },
      deep: true,
    }
  },
  methods: {
    cancel () {
      this.$router.go(-1)
    },
    baseData () {
      return this.$storage.get('baseData')
    },
    // 所有人类型
    allType () {
      return this.baseData().owner_type.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 证件类型
    documentType () {
      return this.baseData().owner_card_type.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 金融方案
    finance () {
      return this.baseData().loan_flag.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 上牌资质
    Registration () {
      return this.baseData().license_flag.map(item => {
        return { 'label': item.key, 'value': item.value, 'key': item.key }
      })
    },
    // 获取上牌城市
    async getCity () {
      const res = await getBrandCity()
      if (res.success) {
        this.cityList = res.data.map(item => {
          return { 'label': item.cityName, 'value': item.intentionCity }
        })
      } else {
        Toast('获取城市数据失败')
      }
    },
    // 获取客户详情
    async getAccountInfo () {
      const res = await accountDetail({ id: this.accountId })
      if (res.success) {
        this.userInfo = res.data
        this.model.name = res.data.name
      } else {
        Toast(res.msg)
      }
    },
    // 获取poc详情
    async getPocInfo () {
      const res = await pocInfo({ 'oppid': this.oppId })
      if (res.success) {
        // this.pocDetail = res.data
        this.model.type = res.data.ownerType || ''
        this.model.name = res.data.name || this.userInfo.name
        this.model.cardType = res.data.ownerCardType || ''
        this.model.cardNo = res.data.cardNo || ''
        this.model.prisename = res.data.enterpriseName || ''
        this.model.code = res.data.enterpriseCode || ''
        this.model.loanFlag = res.data.loanFlag || ''
        this.model.licenseFlag = res.data.licenseFlag || ''
        this.model.licenseCity = res.data.licenseCity || ''
        this.model.namea = res.data.mainName || ''
        this.model.phone = res.data.mainPhone || ''
        this.model.remark = res.data.remark || ''
        this.model.cardNoa = res.data.mainCardNo || ''
      } else {
        Toast(res.msg)
      }
    },
    onSave () {
      this.$refs.form.validate()
        .then(res => {
          if (res === true) {
            this.savePoc(this.model)
            // // console.log(this.model)
            // this.checkformat(this.model)
          }
        })
    },
    // 输入验证
    // checkformat (check) {
    //   if (check.name) {
    //     if (check.name.length >= 20) return Toast('姓名长度不得超过20') // 长度判断
    //     if (/\s\s\s*/.test(check.name)) return Toast("姓名不允许过多空格，请重填")// 姓名验证
    //   }
    //   if (check.namea) {
    //     if (check.name >= 20) return Toast('姓名长度不得超过20') // 长度判断
    //     if (/\s\s\s*/.test(check.namea)) return Toast("姓名不允许过多空格，请重填") // 姓名验证
    //   }
    //   if (check.cardNo) {
    //     if (!/^[A-Za-z0-9]+$/.test(check.cardNo)) return Toast('证件号输入格式有误，请重填')// 证件号验证
    //     if (check.cardNo.length >= 30) return Toast('证件号长度不得超30') // 长度判断
    //   }
    //   if (check.prisename) {
    //     if (check.prisename.length >= 30) return Toast("长度不超过30")// 企业名称验证
    //     if (/\s\s\s\s\s*/.test(check.prisename)) return Toast("企业名称空格过多，请重填")
    //   }
    //   if (check.code) {
    //     if (/\s\s\s\s\s*/.test(check.code) && check.code) return Toast("企业代码空格过多，请重填")  // 企业代码验证
    //     if (check.prisename.length >= 30) return Toast("企业代码长度不超过30")
    //   }
    //   if (check.phone) {
    //     if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(check.phone) && check.phone) return Toast("手机号码有误，请重填") // 手机号验证
    //   }
    //   this.savePoc(check) // 确认
    // },
    // poc确定按钮
    async savePoc (name) {
      const obj = {
        eosOppId: this.oppId,  // 接收传值
        ownerType: name.type,
        name: name.name,
        ownerCardType: name.cardType,
        cardNo: name.cardNo,
        loanFlag: name.loanFlag,
        licenseFlag: name.licenseFlag,
        licenseCity: name.licenseCity,
        remark: name.remark,
        mainName: name.namea,
        mainPhone: name.phone,
        enterpriseName: name.prisename,
        enterpriseCode: name.code,
        mainCardNo: name.cardNoa
      }
      const res = await oppSave(obj)
      if (res.success) {
        Toast('提交成功')
        this.$router.go(-1)
      } else {
        Toast(res.msg)
      }
    },
    // 初始化
    async init () {
      const params = this.$route.query
      // 获取页面传递过来的用户id以及oppid
      this.accountId = params.accountId
      this.oppId = params.oppId
      this.baseData()
      this.getCity()
      await this.getAccountInfo()
      await this.getPocInfo()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
.footer-btn {
  position: unset;
}
</style>